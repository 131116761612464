(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

(function ($, window, document, undefined) {
  $.fn.quicksearch = function (target, opt) {
    var timeout,
        cache,
        rowcache,
        jq_results,
        val = '',
        e = this,
        options = $.extend({
      delay: 100,
      selector: null,
      stripeRows: null,
      loader: null,
      noResults: '',
      matchedResultsCount: 0,
      bind: 'keyup',
      onBefore: function onBefore() {
        return;
      },
      onAfter: function onAfter() {
        return;
      },
      show: function show() {
        this.style.display = "";
      },
      hide: function hide() {
        this.style.display = "none";
      },
      prepareQuery: function prepareQuery(val) {
        return val.toLowerCase().split(' ');
      },
      testQuery: function testQuery(query, txt, _row) {
        for (var i = 0; i < query.length; i += 1) {
          if (txt.indexOf(query[i]) === -1) {
            return false;
          }
        }

        return true;
      }
    }, opt);

    this.go = function () {
      var i = 0,
          numMatchedRows = 0,
          noresults = true,
          query = options.prepareQuery(val),
          val_empty = val.replace(' ', '').length === 0;

      for (var i = 0, len = rowcache.length; i < len; i++) {
        if (val_empty || options.testQuery(query, cache[i], rowcache[i])) {
          options.show.apply(rowcache[i]);
          noresults = false;
          numMatchedRows++;
        } else {
          options.hide.apply(rowcache[i]);
        }
      }

      if (noresults) {
        this.results(false);
      } else {
        this.results(true);
        this.stripe();
      }

      this.matchedResultsCount = numMatchedRows;
      this.loader(false);
      options.onAfter();
      return this;
    };
    /*
     * External API so that users can perform search programatically. 
     * */


    this.search = function (submittedVal) {
      val = submittedVal;
      e.trigger();
    };
    /*
     * External API to get the number of matched results as seen in 
     * https://github.com/ruiz107/quicksearch/commit/f78dc440b42d95ce9caed1d087174dd4359982d6
     * */


    this.currentMatchedResults = function () {
      return this.matchedResultsCount;
    };

    this.stripe = function () {
      if (_typeof(options.stripeRows) === "object" && options.stripeRows !== null) {
        var joined = options.stripeRows.join(' ');
        var stripeRows_length = options.stripeRows.length;
        jq_results.not(':hidden').each(function (i) {
          $(this).removeClass(joined).addClass(options.stripeRows[i % stripeRows_length]);
        });
      }

      return this;
    };

    this.strip_html = function (input) {
      var output = input.replace(new RegExp('<[^<]+\>', 'g'), "");
      output = $.trim(output.toLowerCase());
      return output;
    };

    this.results = function (bool) {
      if (typeof options.noResults === "string" && options.noResults !== "") {
        if (bool) {
          $(options.noResults).hide();
        } else {
          $(options.noResults).show();
        }
      }

      return this;
    };

    this.loader = function (bool) {
      if (typeof options.loader === "string" && options.loader !== "") {
        bool ? $(options.loader).show() : $(options.loader).hide();
      }

      return this;
    };

    this.cache = function () {
      jq_results = $(target);

      if (typeof options.noResults === "string" && options.noResults !== "") {
        jq_results = jq_results.not(options.noResults);
      }

      var t = typeof options.selector === "string" ? jq_results.find(options.selector) : $(target).not(options.noResults);
      cache = t.map(function () {
        return e.strip_html(this.innerHTML);
      });
      rowcache = jq_results.map(function () {
        return this;
      });
      /*
       * Modified fix for sync-ing "val". 
       * Original fix https://github.com/michaellwest/quicksearch/commit/4ace4008d079298a01f97f885ba8fa956a9703d1
       * */

      val = val || this.val() || "";
      return this.go();
    };

    this.trigger = function () {
      this.loader(true);
      options.onBefore();

      if (typeof timeout !== "undefined") {
        clearTimeout(timeout);
      }

      timeout = setTimeout(function () {
        e.go();
      }, options.delay);
      return this;
    };

    this.cache();
    this.results(true);
    this.stripe();
    this.loader(false);
    return this.each(function () {
      /*
       * Changed from .bind to .on.
       * */
      $(this).on(options.bind, function () {
        val = $(this).val();
        e.trigger();
      });
    });
  };
})(jQuery, void 0, document);

},{}]},{},[1]);
